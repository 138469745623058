import Caption from "../text/caption"
import SectionGrid from "../section-grid/section-grid"
import CompanyOverviewImage from "../../public/img/sections/company_overview_512_320.jpg";
import AnnualReportsImage from "../../public/img/sections/annual_reports_512_320.jpg";
import CompanyAnnouncementsImage from "../../public/img/sections/company_announcements_512_320.jpg";
import ShareholderInformationImage from "../../public/img/sections/shareholder_information_512_320.jpg";
import BoardOfDirectorsImage from "../../public/img/sections/board_of_directors_512_320_02.jpg";
import ManagementTeamImage from "../../public/img/sections/management_team_512_320_05.jpg";

export default function WebsiteSections(props) {

    const { className } = props;

    return (
        <section className={ "bg-grey text-center" + (className ? " " + className : "") }>
            <div className="container py-60">
                <Caption>Latest News</Caption>
                <h2 className="text-h1 pb-30">Learn more about us</h2>
                <SectionGrid
                    items={
                        [
                            {
                                href: "/investors/company-announcements",
                                className: "bg-white",
                                image: CompanyAnnouncementsImage,
                                title: "Company announcements",
                                subtext: "Search for recent and past announcements or subscribe to receive announcements to your inbox.",
                            },
                            {
                                href: "/investors/annual-reports",
                                className: "bg-white",
                                image: AnnualReportsImage,
                                title: "Annual reports",
                                subtext: "Download our latest Annual Report and find out how we're accelerating profitable growth.",
                            },
                            {
                                href: "/about-us/company-overview",
                                className: "bg-white",
                                image: CompanyOverviewImage,
                                title: "Company overview",
                                subtext: "Find out about our core purpose and values in the automotive and personal lending space .",
                            },
                            {
                                href: "/investors/shareholder-information",
                                className: "bg-white",
                                image: ShareholderInformationImage,
                                title: "Shareholder information",
                                subtext: "Find out how to view your shareholding information, dividend payments and update your details.",
                            },
                            {
                                href: "/about-us/board-of-directors",
                                className: "bg-white",
                                image: BoardOfDirectorsImage,
                                title: "Board of directors",
                                subtext: "Meet the Board of Directors of Solvar Limited.",
                            },
                            {
                                href: "/about-us/management-team",
                                className: "bg-white",
                                image: ManagementTeamImage,
                                title: "Management team",
                                subtext: "Meet the Management Team of Solvar Limited.",
                            },
                        ]
                    }
                />
            </div>
        </section>
    )
}